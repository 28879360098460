import { Upload, message, Typography, Space, Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import EXIF from "exif-js";

const { Text } = Typography;

function isJpgOrPng(file) {
  return file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp';
}

const ImageUpload = (props) => {
  const [previewVisible, setPreviewVisible] = useState(false);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const beforeUpload = (file, filelist) => {
    if (!isJpgOrPng(file)) {
      message.error('只支持上传JPG或PNG文件');
      return false;
    }
    if (filelist.length > 1) {
      message.error('暂不支持多张图片同时上传');
      return false;
    }
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error('只支持上传大小小于10M的图片');
      return false;
    }
    return true
  };

  const handleChange = (info) => {
    const { status } = info.file;
    if (status === 'done') { // 状态有：uploading done error removed，被 beforeUpload 拦截的文件没有 status 属性
      $('.ant-upload-list-picture-card-container').show();
      getBase64(info.file.originFileObj, async (base64) => {
        EXIF.getData(info.file.originFileObj, function () {
          const orientation = EXIF.getTag(this, "Orientation");
          console.log(orientation)
          props.setOrientation(orientation);
          props.setUploadImage(base64);
        });
      });
    } else {
      $('.ant-upload-list-picture-card-container').hide();
    }
  };

  const handlePreview = () => {
    setPreviewVisible(true);
  };

  return <>
    <Upload
      accept={"image/*"}
      maxCount={1}
      listType="picture-card"
      onPreview={handlePreview}
      beforeUpload={beforeUpload}
      onChange={handleChange}
      onRemove={() => {
        props.setUploadImage(undefined);
      }}
      customRequest={({
        onSuccess,
      }) => {
        if (onSuccess) {
          onSuccess(undefined);
        }
      }}
    >
      {props.uploadImage ? null : <Space direction='vertical'>
        <UploadOutlined />
        <Text>{'上传图片'}</Text>
      </Space>}
    </Upload>
    <Modal
      destroyOnClose
      open={previewVisible}
      footer={null}
      title={'查看图片'}
      onCancel={() => setPreviewVisible(false)}
    >
      <img alt="example" style={{ width: '100%' }} src={props.uploadImage} />
    </Modal>
  </>;
};
export default ImageUpload;
