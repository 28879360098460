import { useState, Suspense } from 'react'
import { Canvas, useLoader } from '@react-three/fiber'
import { OrbitControls, Environment, useProgress, Html, Center } from '@react-three/drei'
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import * as THREE from "three";
import { DDSLoader } from "three-stdlib";
import { Image } from 'antd';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import { useEffect } from 'react';

THREE.DefaultLoadingManager.addHandler(/\.dds$/i, new DDSLoader());

const Scene = (props) => {

  const baseUrl = 'https://stable-diffusion-1251539703.cos.ap-chengdu.myqcloud.com/3dface/'
  const [obj, setObj] = useState()
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const materialLoader = new MTLLoader();
    materialLoader.setPath(baseUrl)
    const objLoader = new OBJLoader();
    objLoader.setPath(baseUrl)
    const textureLoader = new TextureLoader();
    textureLoader.setPath(baseUrl)
    materialLoader.load("./testmtl.mtl", material => {
      material.preload();
      objLoader.setMaterials(material)
      objLoader.load(props.objUrl, obj => {
        textureLoader.load("./texture.jpg", texture => {
          texture.colorSpace = THREE.SRGBColorSpace
          obj.children[0].material.map = texture
          setObj(obj)
        })
      }, event => {
        // console.log(event.loaded / event.total)
        setProgress((event.loaded / event.total * 100).toFixed(0))
      })
    })
  }, [])

  return <mesh>
    {obj && <primitive object={obj} scale={0.1} />}
    {!obj && <Html center>{progress}%</Html>}
  </mesh>;
};

function Loader() {
  const { progress } = useProgress()
  return <Html center>{progress.toFixed(0)}%</Html>
}

export default function Home() {
  const objUrl = './testobj.obj';

  return (
    <>
      <Canvas eventPrefix="client" camera={{ position: [0, 0, 0.5], fov: 40 }}>
        <Suspense fallback={<Loader />}>
          <Center>
            <Scene objUrl={objUrl} />
          </Center>
          <OrbitControls />
          <ambientLight intensity={0.7} />
          {/* <spotLight intensity={0.5} angle={0.1} penumbra={1} position={[0, 0, 50]} castShadow /> */}
          {/* <directionalLight intensity={0.5} position={[0, 0, 50]} /> */}
          {/* <Environment preset="city" background blur={1} /> */}
        </Suspense>
      </Canvas>
      <div style={{ position: 'absolute', top: 0 }}>
        <Image
          width={200}
          src="https://stable-diffusion-1251539703.cos.ap-chengdu.myqcloud.com/3dface/testface.png"
        />
      </div>
    </>
  )
}
