import { useEffect, useState, Suspense } from 'react'
import { Canvas } from '@react-three/fiber'
import { OrbitControls, Environment, useProgress, Html, Center } from '@react-three/drei'
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import * as THREE from "three";
import { DDSLoader } from "three-stdlib";
import ImageUpload from './components/ImageUpload';
import { notification, Spin } from 'antd';
import { TextureLoader } from 'three/src/loaders/TextureLoader'

THREE.DefaultLoadingManager.addHandler(/\.dds$/i, new DDSLoader());

const Scene = (props) => {
  const baseUrl = 'https://stable-diffusion-1251539703.cos.ap-chengdu.myqcloud.com/'
  const [obj, setObj] = useState()
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    setObj(undefined)
    const objLoader = new OBJLoader();
    objLoader.setPath(baseUrl)
    const textureLoader = new TextureLoader();
    textureLoader.setPath(baseUrl)
    objLoader.load(props.objUrl, obj => {
      textureLoader.load(props.jpgUrl, texture => {
        texture.colorSpace = THREE.SRGBColorSpace
        obj.children[0].material.map = texture
        setObj(obj)
      })
    }, event => {
      // console.log(event.loaded / event.total)
      setProgress((event.loaded / event.total * 100).toFixed(0))
    })
  }, [props.objUrl])

  return <mesh>
    {obj && <primitive object={obj} scale={0.1} />}
    {!obj && <Html center>{progress}%</Html>}
  </mesh>;
};

function Loader() {
  const { progress } = useProgress()
  return <Html center>{progress.toFixed(0)}%</Html>
}

export default function Upload() {
  const [uploadImage, setUploadImage] = useState();
  const [orientation, setOrientation] = useState();
  const [api, contextHolder] = notification.useNotification();
  const [objUrl, setObjUrl] = useState();
  const [jpgUrl, setJpgUrl] = useState();
  const [loading, setLoading] = useState(false);

  const openNotificationWithIcon = (type, msg) => {
    api[type]({
      message: '出错了',
      description: msg,
    });
  };

  useEffect(() => {
    if (uploadImage) {
      setLoading(true)
      fetch('https://sz405.ininpop.com/face/face_reconstruction', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8',
          'Authorization': 'Basic dG96OnRvemluaW5wb3A='
        },
        body: JSON.stringify({
          orientation: orientation,
          image: uploadImage.replace(/^data:image\/\w+;base64,/, "")
        })
      })
        .then(response => response.json())
        .then(json => {
          console.log(json)
          if (json.code === '0') {
            setObjUrl(`${json.data.obj}`)
            setJpgUrl(`${json.data.jpg}`)
          } else {
            openNotificationWithIcon('error', json.msg)
          }
        })
        .catch(err => {
          console.log('Request Failed', err)
          openNotificationWithIcon('error', err.message)
        })
        .finally(() => setLoading(false))
    }
  }, [uploadImage])

  return (
    <>
      {contextHolder}
      <Spin
        tip="Loading"
        size="large"
        spinning={loading}
      >
        <div style={{ width: '100vw', height: '100vh' }}>
          {objUrl && <Canvas eventPrefix="client" camera={{ position: [0, 0, 0.5], fov: 40 }}>
            <Suspense fallback={<Loader />}>
              <Center>
                <Scene objUrl={objUrl} jpgUrl={jpgUrl} />
              </Center>
              <OrbitControls />
              <ambientLight intensity={0.5} />
              <spotLight intensity={0.5} angle={0.1} penumbra={1} position={[10, 15, 5]} castShadow />
              {/* <Environment preset="city" background blur={1} /> */}
            </Suspense>
          </Canvas>}
        </div>
      </Spin>
      <div style={{ position: 'absolute', top: 0 }}>
        <ImageUpload
          uploadImage={uploadImage}
          setUploadImage={setUploadImage}
          setOrientation={setOrientation}
        />
      </div>
    </>
  )
}
