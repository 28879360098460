import { HashRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Upload from "./Upload";

export default function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/upload" element={<Upload />} />
      </Routes>
    </HashRouter>
  );
}
